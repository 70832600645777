import { ReactElement } from 'react'
import { tag, newTag as T} from './Tag'

import rice_logo from '../resources/rice_logo.png'
import amazon_logo from '../resources/amazon_logo.png'
import mda_logo from '../resources/mda_logo.png'
import rice_cs_logo from '../resources/rice_cs_logo.jpg'
import riceapps from '../resources/riceapps.png'
import zearch from '../resources/zearch.png'
import particleLife from '../resources/particleLife.png'
import oweek from '../resources/oweek.png'
import bloomfilter from '../resources/bloomfilter.png'
// import nsf from '../resources/nsf.png'
import sstable from '../resources/sstable.png'
import feistel from '../resources/feistel.png'

export interface EntryData { 
    header?: string,
    subHeader?: string,
    upperMinor?: string,
    lowerMinor?: string,
    body?: ReactElement<any,any> | string,
    href?: string,
    secondaryHref?: string,
    tags?: tag[],
    image?: any,
    entries?: EntryData[],
    isKey?: boolean
}

export interface SectionData{
    name: string;
    type?: string;
    entries: EntryData[];
}

export const DATA: SectionData[] = [
    {
        name: '👋 About Me',
        type: "block",
        entries: [
            {
                body: <>
                {/* <p> 
                I am an aspiring academic researcher. 
                Currently, I am a professional software engineer with a passion for solving hard problems.
                </p> */}
                <p> 
                My programming journey started in 2013 when I taught myself Lua in order to make multiplayer games on Roblox; my games garned over 1,300,000 plays.
                This experience motivated me to study Mathematics and Computer Science at Rice University.
                For the past year, I have been learning new skills and tackling big challenges at Amazon Web Services. 
                </p>
                {/* <p>
                Now, I am seeking a Computer Science Ph.D position. I have earned an NSF Fellowship, which provides 3 years of funding. 
                If you have an exciting opportunity, please feel free to reach out to me at <a href="mailto:adzawie@gmail.com">adzawie@gmail.com</a>!
                </p> */}
                </>
            }
        ]
    },
    // {name: '💵 Funding',
    //     entries: [
    //         {
    //             header: 'National Science Foundation',
    //             image: nsf,
    //             subHeader: `Computer and Information Science and Engineering Graduate Fellowship (CS4GradUSA)`,
    //             upperMinor: '2024',
    //             href: "https://new.nsf.gov/cise/graduate-fellowships",
    //             body: "Earned a $159,000 fellowship to pursue graduate education and research in Computer Science",
    //             isKey: true
    //         }
    //     ]
    // },
    {name: '🎓 Education',
        entries: [
            {
                header: 'Rice University',
                image: rice_logo,
                subHeader: `Bachelor's Degree, Mathematics ${"&"} Computer Science`,
                upperMinor: '',
                lowerMinor: '3.87 GPA',
                body: "Jackie Schnell Memorial Scholarship Recipient, Academic Fellow, President's Honor Roll, Honor Council Representative",
                isKey: true
            }
        ]
        },
    {
        name: "📄 Articles",
        type: "card",
        entries: [
            { 
                header: "Adam Zawierucha is developing products and communication skills",
                subHeader: "Rice Engineering Article Feature",
                image: "https://engineering.rice.edu/sites/g/files/bxs2626/files/2022-03/activate_Adam_Zawierucha.jpg",
                href: "https://engineering.rice.edu/news/adam-zawierucha-developing-products-and-communication-skills"
            },
            {
                header: "Rice Public Art app designed by students available now",
                subHeader: "Rice Public Art Press Release",
                image: "https://news.rice.edu/sites/g/files/bxs2656/files/2021-07/Moody-Graphic.png",
                href: "https://news.rice.edu/news/2021/rice-public-art-app-designed-students-available-now"
            }
        ]
    },
    {
        name: "📍 Personal Projects",
        type: "project",
        entries: [
            {
                header: "Hierarchical Bloom Filters",
                image: bloomfilter,
                body: <>
                    <h3> Problem </h3>
                    <p> Bloom filters are used ubiquitously due to their speed and memory efficiency in theory and in practice. 
                        However, the standard implementation of sufficiently large bloom filters suffers from page faults. 
                     </p>
                     <h3> Solution </h3>
                     <p>
                        We can create a bloom filter implementation that guarantees one page access per operation. 
                        This minimizes page faults, thereby drasticaly improving efficiency.
                        I show theoretically and empirically that this hierarchical implementation is expected to be faster than the standard implementation without alterating the false positive rate.
                     </p>
                </>,
                href: "/files/AdamZawieruchaBloomFilter.pdf",
                secondaryHref: "https://github.com/zawie/hierarchical-bloomfilters",
                tags: [T('C','geekblue'), T('Bash','geekblue'), T('Research','gold')],
            },
            {
                header: "Sorted String Table Database",
                body: <>
                <h3> About </h3>

                Designed and implemented a fault tolerant SS-Table based key-value database in Rust

                </>,
                image: sstable,
                secondaryHref: "https://github.com/zawie/zdb",
                tags: [T('Rust','geekblue'), T('Systems', 'gold')],
            },
            {
                header: "Fiestel Cipher",
                body: <>
                Implemented a feinstel cipher in Rust enbaling encryption of arbitrary amounts of data with a 64 bit key;
                implemented with counter mode to ensure identical blocks within the same stream would be encrypted distinctly                
                </>,
                image: feistel,
                secondaryHref: "https://github.com/zawie/cryptography",
                tags: [T('Rust','geekblue'), T('Cryptography', 'gold')],
            },
            {
                header: "Particle Life",
                body: <>
                <h3> About </h3>
                Particle life is a particle simulator implemented in Golang showcasing emergent behavior.

                Each particle color is randomly assigned different repelling or attracting forces to other particles. Conservation of energy is not required and pairs of forces do not need to be opposite and equal. (Newton is rolling in his grave!)

                This simple system displays emergent behavior as life like structures form under these simple constraints.
                </>,
                image: particleLife,
                secondaryHref: "https://github.com/zawie/particle-life",
                tags: [T('Go','geekblue')],
            },
            {
                header: "Search Engine",
                body: <>
                <h3> About </h3>
                <p> This is a fully functional search engine built from scratch made of three primary components. 
                    <ul>
                        <li>A web crawler to scrape and index the websites. </li>
                        <li>Search engine which quickly finds websites based on your query using a minhashtable and comparing relative frequency of trigrams.</li>         
                        <li>A slick frontend using React, which interfaces with the backend engine. </li>
                    </ul> 
                </p>
                </>,
                image: zearch,
                secondaryHref: "https://github.com/zawie/zearch-engine",
                tags: [T('Java','geekblue'), T('SQL','geekblue'), T('React','blue')],
            },
            {
                header: "O-Week Geneaology",
                body: <>
                    <h3> 
                        Problem
                    </h3>
                    <p> 
                        Every Rice student is assigned to an orientation week "famlily" and upperclassmen "parents".
                        Students wonder how they are related to their peers in this family hierarchy,
                        but there is no central place to do it.
                    </p>

                    <h3> 
                        Solution
                    </h3>
                    <p> 
                        A site that dynamically pulls and render data about who belongs to which o-week family and how they all connect.
                        Now, students can easily search and explore our family o-week tree. Currently over <b> 3,000 students </b> are represented in the dataset, and growing!
                    </p>
                </>,
                image: oweek,
                href: "https://oweek.zawie.io",
                secondaryHref: "https://github.com/zawie/oweek.zawie.io",
                tags: [T('TypeScript','geekblue'), T('React','blue'), T('Next.js','blue'), T('Full Stack Development','cyan')],
            }
        ]
    },
    {name: '💼 Industry Experience',
        entries: [
            {
                header: 'Amazon',
                isKey: true,
                image: amazon_logo,

            entries: [
                {
                    subHeader: 'Software Engineer',
                    lowerMinor: '2023 - Present',
                    tags: [T('Amazon Web Services (AWS)','blue'), T('Go', 'geekblue'), T('Python', 'geekblue')],
                    body: "Work on EC2 health analytics, a team which is responsible for building distributed services and tools for automatic fault analysis, aggregating fleet health metrics, and providing visualization and reporting of the health data for the entire global AWS EC2 fleet consisting of physical servers and virtual instances. The team uses sophisticated big data analytics, correlation, and machine learning techniques at peta-scale to drive fleet wide improvement in reliability and customer experience."
                },
                {
                    subHeader: 'Software Engineer Intern',
                    lowerMinor: 'Summer 2022',
                    tags: [T('Amazon Web Services (AWS)','blue'), T('Java','geekblue'), T('Ruby','geekblue'), T('Bash','geekblue')],
                    body: <ul>
                    <li>Wrote integration tests using Java in a Continuous Integration/Deployment (CI/CD) pipeline</li>
                    <li>Researched, presented, and onboarded an internal AWS testing framework (Hydra)</li>
                    <li>Wrote infrastructure as code in Ruby in order to auto deploy and update operation scripts written in Bash</li> 
                </ul> 
                },
                {
                    subHeader: 'Software Engineer Intern',
                    lowerMinor: 'Summer 2021',
                    tags: [T('React','blue'), T('Express','blue'), T('Full Stack Development','cyan'), T('TypeScript','geekblue')],
                    body: <ul>
                    <li>Developed internal tools using TypeScript, Express, and React: a PRO generator and an interface to look up information about shipments.</li>
                    <li>Created design documents, debugged pre-existing errors, worked in an agile development setting, and presented projects</li>         
                </ul> 
                },
            ]
            },
            {
                header: 'MD Anderson Cancer Center',
                isKey: true,
                image: mda_logo,
                subHeader: 'Software Developer',
                upperMinor: 'January - May 2021',
                tags: [T('React','blue'), T('Express','blue'), T('SQL','geekblue'), T('C#','geekblue')],
                body: 'Developed an internal dashboard from scratch to monitor tasks processed by a diagnostic radiotherapy AI system (RPA).'
            },
        ]
   },
    {
       name: "✏️ Extracurriculars",
       entries: [
            {
                header: 'RiceApps',
                image: riceapps,
                isKey: true,
                upperMinor: '2019 - 2023',
                body: <p>A student organization building digital solutions for social good.</p>,
                entries: [
                    {
                        subHeader: 'President',
                        lowerMinor: 'Junior & Senior Year',
                        tags: [T('Leadership','gold')],
                        body: <ul>
                        <li>Sourced projects with non-profit organizations in the Houston areas</li>
                        <li>Oveersee technical deeevelopment and product design for 5 teams of 10 developers</li>
                        <li>Organizd a summer program with 30 participants that teaches software development skills</li>

                    </ul>
                    },
                    {
                        subHeader: 'Team Lead',
                        lowerMinor: 'Sophomore Year',
                        tags: [T('React','blue'), T('Leadership','gold')],
                        body: <ul>
                            <li>Lead a team to develop a React app to solve matching problems for Rice CCD, Hives for Heroes, and Covidsitters.</li>
                            <li>Developed a matching algorithm for the Rice Career Center that increased externship matches by 5% and cut down processing time from days to seconds</li>
                        </ul>
                    },
                    {
                        subHeader: 'Developer',
                        lowerMinor: 'Freshman Year',
                        tags: [T('React Native','blue')],
                        body:  <ul>
                            <li>Developed an iOS app that gives users information about public art installations on Rice campus using React Native and node.js.</li>
                        </ul>
                    },
                ]
            },
            {
                header: 'Rice Computer Science Department',
                image: rice_cs_logo,
                subHeader: '',
                entries: [
                    {
                        subHeader: 'Head Teaching Assistant',
                        lowerMinor: '2020 - 2023',
                        tags: [T('Algorithms','cyan'), T('Data Structures','cyan'), T('Teaching','gold') ],
                        body: 'Facilitated labs, ran office hours, and graded assignments and exams for an upper-level data structures and algorithms and a discrete mathematics class.'
                    },
                    {
                        subHeader: 'Research Assistant',
                        lowerMinor: '2023',
                        tags: [T('Research','cyan'), T('Probabilistic Data Structures','cyan'), T('Python','geekblue'), T('C++','geekblue') ],
                        body:  <ul>
                            <li> Implemented a novel streaming algorithm in C++ that diversily samples unlabeled genemoic sequences using count sketches </li>
                        </ul>   
                    },
                    {
                        subHeader: 'Research Assistant',
                        lowerMinor: '2022',
                        tags: [T('Research','cyan'), T('Formal Methods','cyan'), T('Rust','geekblue'), T('C','geekblue') ],
                        body:  <ul>
                            <li> Implemented Glushkov’s construction algorithm in a lab designing regex engines to handle bounded counters efﬁciently</li>
                        </ul>   
                    },
                    {
                        subHeader: 'Research Assistant',
                        lowerMinor: '2020',
                        tags: [T('Research','cyan'), T('Deep Learning','cyan'), T('Python','geekblue'), T('PyTorch','blue') ],
                        body: <ul>
                                <li>Apply deep learning techniques to resolve molecular phylogenetic quartets</li>
                                <li>Utilize PyTorch to train machine learning models on simulated genetic sequences</li>          
                            </ul>  
                    },
                ]
            }
       ]
   },
]